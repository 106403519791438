<template>
    <div>
      <form role="form" @submit="onFormSubmit" name="site-form">
        <TextField 
          ref="updateName"
          v-bind:value.sync="siteForm.name" 
          id="updateName" 
          :label="$t('cms.site.info.name')" 
          :placeholder="$t('cms.site.info.name_placeholder')" 
          :labelAsColumn="true"
          :required="true"
        />
        <TextField 
            ref="updateCode"
            v-bind:value.sync="siteForm.code" 
            id="updateCode" 
            :label="$t('cms.site.info.code')" 
            :placeholder="$t('cms.site.info.code_placeholder')" 
            :labelAsColumn="true"
            :required="true"
        />
        <ListTextFields
            ref="updateDomains"
            v-bind:value.sync="siteForm.domains" 
            id="updateDomains" 
            :label="$t('cms.site.info.domains')" 
            :placeholder="$t('cms.site.info.domains_placeholder')" 
            :labelAsColumn="true"
            :required="true"
        />
        <Select
          :label="$t('cms.site.info.company')"
          :placeholder="$t('cms.site.info.company_placeholder')"
          :selectOptions="{
            options: listCompanies,
            getOptionLabel : option => option.name
          }"
          v-bind:value.sync="siteForm.company" 
          :labelAsColumn="true"
          :required="true"
        />
        <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="siteForm.isActivated" 
            id="updateIsActivated" 
            :label="$t('cms.site.info.isActivated')" 
            :labelAsColumn="true"
        />
        <TextField 
            :textarea="true"
            ref="updateConfiguration"
            v-bind:value.sync="siteForm.configuration" 
            id="updateConfiguration" 
            :label="$t('cms.site.info.configuration')" 
            :placeholder="'JSON configuration'" 
            :labelAsColumn="true"
        />
        <Select
          v-if="listShops.length > 0"
          :label="$t('cms.site.info.shop')"
          :placeholder="$t('cms.site.info.shop_placeholder')"
          :selectOptions="{
            options: listShops,
            getOptionLabel : option => option.name
          }"
          v-bind:value.sync="siteForm.shop" 
          :labelAsColumn="true"
        />
        <Select
          v-if="feeds.length > 0"
          :label="$t('cms.site.info.feeds')"
          :placeholder="$t('cms.site.info.feeds_placeholder')"
          :selectOptions="{
            multiple: true,
            options: availableFeeds,
            getOptionLabel : option => option.name
          }"
          v-bind:value.sync="siteForm.feeds" 
          :labelAsColumn="true"
        />
        <Select
          v-if="agencies.length > 0"
          :label="$t('cms.site.info.agencies')"
          :placeholder="$t('cms.site.info.agencies_placeholder')"
          :selectOptions="{
            multiple: true,
            options: availableAgencies,
            getOptionLabel : option => option.name
          }"
          v-bind:value.sync="siteForm.agencies" 
          :labelAsColumn="true"
        />
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
      </form>
      <SiteAdminForm :site="site" />
    </div>

</template>


<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import SiteAdminForm from './SiteAdminForm.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import ListTextFields from '@fwk-client/components/panels/input/ListTextFields.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';
import { useShopAdmin } from '../../../../../shop/composables/useShopAdmin';
import { useFeedAdmin } from '../../../../../blog/composables/useFeedAdmin';
import { useAgencyAdmin } from '../../../../../hospitality/composables/useAgencyAdmin';
import { useModuleAdmin as useCompanyModuleAdmin } from '../../../../../company/composables/useModuleAdmin';

export default defineComponent({
  props: {
      site: {
        type: Object,
        required: false
      }
  },
  components: {
      SwitchCheck, TextField, ListTextFields, Select, SiteAdminForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { sites, selectedSite, siteForm, createSite, updateSite, updateSiteFormForUpdate } = useSiteAdmin(props, context);
    const { shops } = useShopAdmin(props, context);
    const { feeds } = useFeedAdmin(props, context);
    const { agencies } = useAgencyAdmin(props, context);
    const { modules:listCompanies } = useCompanyModuleAdmin(props, context);

    const { site } = toRefs(props);

    const listShops = computed(() => {
      return shops.value.map((shop:any) => {
        return shop.shop;
      })
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const availableFeeds = computed(() => {
      return feeds.value.map((feed:any) => {
        return feed.feed;
      }).filter((feed:any) => {
        if(!siteForm.feeds) { return true; }
        else {
          return siteForm.feeds.map((formFeed:any) => formFeed._id).indexOf(feed._id) < 0
        }
      })
    });

    const availableAgencies = computed(() => {
      return agencies.value.map((agency:any) => {
        return agency.agency;
      }).filter((agency:any) => {
        if(!siteForm.agencies) { return true; }
        else {
          return siteForm.agencies.map((formAgency:any) => formAgency._id).indexOf(agency._id) < 0
        }
      })
    });

    const buttonLabel = computed(() => {
      if(site && site.value) { return app.$t('cms.site.update.button') }
      else { return app.$t('cms.site.create.button'); }
    })

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=site-form] button' );
      laddaSubmit = Ladda.create(button!);
    })

    updateSiteFormForUpdate(site.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      if(site && site.value) {
        updateSite().then((result:any) => {
          if(result.updated) {
            context.emit('site-updated', result.site);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createSite().then((result:any) => {
          if(result.created) {  
            context.emit('site-created', result.site);
          }
          laddaSubmit!.stop();
        })
      }
    }

    // We watch if we have site ID in URL
    watch(
      site,
      (val:any, oldVal:any) => {
        updateSiteFormForUpdate(site.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      site,
      siteForm,
      listCompanies,
      listShops,
      feeds,
      availableFeeds,
      agencies,
      availableAgencies,
      buttonLabel
    }
  }
})
</script>